import { Banner, Button, List, Text } from "@shopify/polaris";
import { Modal } from "@shopify/polaris";
import { useState } from "react";
import { trackEvent } from "../lib/mixpanel";
import { ToggleOnIcon } from "@shopify/polaris-icons";
import { ChatIcon } from "@shopify/polaris-icons";
import Step1 from "../assets/app-enable-step1.jpg";
import Step2 from "../assets/app-enable-step2.jpg";
import Step3 from "../assets/app-enable-step3.jpg";
import Step4 from "../assets/app-enable-step4.jpg";
import Step5 from "../assets/app-enable-step5.jpg";

export function AppEnableDisableButton() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  function onContactSupport() {
    trackEvent("Contact Support Clicked");
  }

  let message = encodeURIComponent(
    `Hi, I am facing issues in adding the widget on my store. Can you help me out?`
  );
  const link = `https://wa.me/+918076623211?text=${message}`;

  return (
    <>
      <Modal
        onClose={() => {
          setIsModalOpen(false);
        }}
        open={isModalOpen}
        title="Adding widget on store"
      >
        <Modal.Section>
          <Banner
            tone="info"
            children="If you are facing any issues in adding the widget. Click on contact support we will help you out."
            action={{
              content: "Contact Support",
              external: true,
              url: link,
              onAction: onContactSupport,
            }}
          />

          <List type="number">
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Go to Online Store &gt; Themes
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step1}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Click on Customize Theme
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step2}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  In the Apps Section at the end, Click Add block
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step3}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Add the Block with name Whatsapp Customer Support App
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step4}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
            <div style={{ paddingTop: 20 }}>
              <List.Item>
                <Text as="p" variant="bodyLg" fontWeight="medium">
                  Click Save
                </Text>
                <div style={{ paddingTop: 20 }} />
                <img
                  src={Step5}
                  style={{
                    maxWidth: "100%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </List.Item>
            </div>
          </List>
        </Modal.Section>
      </Modal>
      <div style={{ display: "flex", gap: 10 }}>
        <Button
          onClick={onContactSupport}
          url={link}
          external={true}
          icon={ChatIcon}
        >
          Contact Support
        </Button>
        <Button
          icon={ToggleOnIcon}
          onClick={async () => {
            setIsModalOpen(true);
            trackEvent("Add Widget to Storefront Clicked");
          }}
        >
          Add widget on store
        </Button>
      </div>
    </>
  );
}
