import { createBrowserRouter } from "react-router-dom";
import { AppLayout } from "../components/AppLayout";
import { DashboardPage } from "../pages/DashboardPage";
import { SettingsPage } from "../pages/SettingsPage";
import { RootPage } from "../pages/RootPage";
import { PrivacyPolicyPage } from "../pages/PrivacyPolicyPage";
import { AnalyticsPage } from "../pages/AnalyticsPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage to="/app/settings" />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/app",
        element: <DashboardPage />,
      },
      {
        path: "app/analytics",
        element: <AnalyticsPage />,
      },
      {
        path: "/app/settings",
        element: <SettingsPage />,
      },
    ],
  },
]);
