import { useState } from "react";
import { useGetEventsCount } from "../api/useGetEventsCount";
import {
  Page,
  Layout,
  Card,
  Tabs,
  Box,
  SkeletonDisplayText,
  Banner,
  Link,
  InlineStack,
  BlockStack,
} from "@shopify/polaris";
import { formatNumber } from "../utils/formatNumber";
import { AnalyticsChart } from "../components/AnalyticsChart";
import { trackEvent } from "../lib/mixpanel";

// Define period options as a constant to avoid duplication
const PERIOD_OPTIONS = ["today", "yesterday", "last7days", "last30days"];

const tabs = [
  {
    id: PERIOD_OPTIONS[0],
    content: "Today",
    accessibilityLabel: "Today",
    panelID: "today-panel",
  },
  {
    id: PERIOD_OPTIONS[1],
    content: "Yesterday",
    accessibilityLabel: "Yesterday",
    panelID: "yesterday-panel",
  },
  {
    id: PERIOD_OPTIONS[2],
    content: "Last 7 Days",
    accessibilityLabel: "Last 7 days",
    panelID: "last7days-panel",
  },
  {
    id: PERIOD_OPTIONS[3],
    content: "Last 30 Days",
    accessibilityLabel: "Last 30 days",
    panelID: "last30days-panel",
  },
];

export function AnalyticsPage() {
  const [period, setPeriod] = useState(PERIOD_OPTIONS[0]);
  const { data, error, isLoading } = useGetEventsCount({ period });

  const handleTabChange = (selectedTabIndex: number) => {
    setPeriod(PERIOD_OPTIONS[selectedTabIndex]);
  };

  const selectedTabIndex = PERIOD_OPTIONS.indexOf(period);

  function onContactSupport() {
    trackEvent("Contact Support Clicked");
  }

  function onReviewClick() {
    trackEvent("Review App Clicked");
  }

  let message = encodeURIComponent(
    `Hi, I am facing issues in adding the widget on my store. Can you help me out?`
  );
  const link = `https://wa.me/+918076623211?text=${message}`;
  const reviewLink = "https://apps.shopify.com/plainchat-whatsapp-support";

  return (
    <div className="analytics-page">
      <Page
        title="Analytics"
        primaryAction={{
          primary: false,
          content: "Contact Support",
          onAction: onContactSupport,
          external: true,
          url: link,
        }}
      >
        {/* <AnalyticsChart /> */}

        <Box paddingBlock="400">
          <Box paddingBlock="200" paddingInline="100">
            <InlineStack align="space-between" blockAlign="center">
              <Box>
                <h2
                  style={{
                    fontSize: "1.0625rem",
                    lineHeight: "1.5rem",
                    fontWeight: "600",
                  }}
                >
                  Overview
                </h2>
              </Box>

              <Tabs
                tabs={tabs}
                selected={selectedTabIndex}
                onSelect={handleTabChange}
              />
            </InlineStack>
          </Box>

          <Layout>
            <Layout.Section>
              {isLoading ? (
                <Card padding="400">
                  <BlockStack gap="400">
                    <SkeletonDisplayText size="small" />
                    <SkeletonDisplayText size="small" />
                    <SkeletonDisplayText size="small" />
                  </BlockStack>
                </Card>
              ) : error ? (
                <Banner title="Error" tone="critical">
                  <p>Something went wrong loading analytics data.</p>
                </Banner>
              ) : (
                <Card padding="400">
                  <BlockStack gap="400">
                    <div style={{ display: "flex", width: "100%" }}>
                      <Box
                        padding="200"
                        borderInlineEndWidth="025"
                        borderColor="border"
                        width="33.33%"
                      >
                        <BlockStack gap="200" align="center">
                          <Box>
                            <p
                              style={{
                                fontSize: "1.0625rem",
                                lineHeight: "1.5rem",
                                fontWeight: "600",
                              }}
                            >
                              Visitors
                            </p>
                          </Box>
                          <Box>
                            <p
                              style={{
                                fontSize: "1.75rem",
                                lineHeight: "2.25rem",
                                fontWeight: "600",
                              }}
                            >
                              {formatNumber(data?.data?.visitors || 0)}
                            </p>
                          </Box>
                        </BlockStack>
                      </Box>

                      <Box
                        padding="200"
                        borderInlineEndWidth="025"
                        borderColor="border"
                        width="33.33%"
                      >
                        <BlockStack gap="200" align="center">
                          <Box>
                            <p
                              style={{
                                fontSize: "1.0625rem",
                                lineHeight: "1.5rem",
                                fontWeight: "600",
                              }}
                            >
                              Views
                            </p>
                          </Box>
                          <Box>
                            <p
                              style={{
                                fontSize: "1.75rem",
                                lineHeight: "2.25rem",
                                fontWeight: "600",
                              }}
                            >
                              {formatNumber(data?.data?.views || 0)}
                            </p>
                          </Box>
                        </BlockStack>
                      </Box>

                      <Box padding="200" width="33.33%">
                        <BlockStack gap="200" align="center">
                          <Box>
                            <p
                              style={{
                                fontSize: "1.0625rem",
                                lineHeight: "1.5rem",
                                fontWeight: "600",
                              }}
                            >
                              Clicks
                            </p>
                          </Box>
                          <Box>
                            <p
                              style={{
                                fontSize: "1.75rem",
                                lineHeight: "2.25rem",
                                fontWeight: "600",
                              }}
                            >
                              {formatNumber(data?.data?.clicks || 0)}
                            </p>
                          </Box>
                        </BlockStack>
                      </Box>
                    </div>
                  </BlockStack>
                </Card>
              )}
            </Layout.Section>
          </Layout>
        </Box>

        {/* Review Request Banner */}
        <Box paddingBlockStart="500" paddingInline="0">
          <Banner title="Enjoying PlainChat WhatsApp Support?" tone="info">
            <BlockStack gap="200">
              <Box>
                <p>
                  Hi there! I'm a solo developer who built this app with ❤️ to
                  help small businesses like yours. If this app has been helpful
                  for your store, your review would mean the world to me and
                  help other merchants discover it too.
                </p>
              </Box>
              <Link
                url={reviewLink}
                target="_blank"
                onClick={onReviewClick}
                monochrome
                removeUnderline
              >
                <Box>
                  <p style={{ fontWeight: "bold" }}>
                    Leave a review on the Shopify App Store →
                  </p>
                </Box>
              </Link>
            </BlockStack>
          </Banner>
        </Box>
      </Page>
    </div>
  );
}
