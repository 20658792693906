import { Provider, useAppBridge } from "@shopify/app-bridge-react";
import { ReactNode, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { configForAppBridgeProvider } from "../lib/configForAppBridgeProvider";
import { AppLink, NavigationMenu } from "@shopify/app-bridge/actions";

interface Props {
  children: ReactNode;
}

const links = {
  "Page 1": "/page1.html",
  "Page 2": "/page2.html",
  "Page 3": "/page3.html",
};

export function AppBridgeProvider({ children }: Props) {
  const navigate = useNavigate();
  const location = useLocation();
  const history = useMemo(
    () => ({ replace: (path: string) => navigate(path, { replace: true }) }),
    [navigate]
  );

  const router = useMemo(
    () => ({
      location,
      history,
    }),
    [location, history]
  );

  return (
    <Provider config={configForAppBridgeProvider} router={router}>
      <NavLinks />
      {children}
    </Provider>
  );
}

function NavLinks() {
  const app = useAppBridge();

  useEffect(() => {
    const links = {
      Settings: "/app/settings",
      Analytics: "/app/analytics",
      "Privacy Policy": "/privacy-policy",
    };

    const items = Object.entries(links).map(([label, destination]) =>
      AppLink.create(app, { label, destination })
    );

    const navigationMenu = NavigationMenu.create(app, { items });
  }, [app]);

  return null;
}
