import { useAppBridge } from "@shopify/app-bridge-react";
import { useQuery } from "@tanstack/react-query";
import { customFetch } from "../lib/customFetch";
import { buildUrl } from "../lib/buildUrl";

export function useGetEventsCount({ period }: { period: string }) {
  const app = useAppBridge();

  const payload = useQuery({
    queryKey: ["getEventsCount"],
    queryFn: async () => {
      return customFetch(buildUrl(`/events/count?period=${period}`), { app });
    },
  });

  return payload;
}
